@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css or src/global.css */

@font-face {
  font-family: 'Pluto Sans';
  src: url('./image/PlutoSansDPD_Desktop/TTF/PlutoSansDPDExtraLight.ttf') format('truetype');
  font-weight: 200; /* Extra Light */
  font-style: normal;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./image/PlutoSansDPD_Desktop/TTF/PlutoSansDPDLight.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./image/PlutoSansDPD_Desktop/TTF/PlutoSansDPDRegular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Pluto Sans';
  src: url('./image/PlutoSansDPD_Desktop/TTF/PlutoSansDPDThin.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: normal;
}

body {
  @apply font-sans;
}